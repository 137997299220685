import { MemberModel } from "@app/models/system/member.model";
import { ValidationUtil } from "./validation.util";
import { CommonConstant } from "@app/common/constant/common.constant";
import { ConvertUtil } from "./convert.util";

export class AuthUtil {
    static readonly TIME_MIN_EXPIRED = 20 * 60 * 1000;

    static actionLogOut() {
        localStorage.removeItem('member');
        localStorage.removeItem('lastAction');
    }
    static isSessionExpired(): boolean {
        let lastAction = localStorage.getItem('lastAction');
        if (lastAction) {
            const now = Date.now();
            const timeLeft = parseInt(lastAction) + AuthUtil.TIME_MIN_EXPIRED;
            const diff = timeLeft - now;
            return diff <= 0;
        } else {
            return true
        }
    }

    static getLoginedInfo(): MemberModel | null {
        let str = localStorage.getItem("member");
        if (ValidationUtil.isNotNullAndNotEmpty(str)) {
            return JSON.parse(str + "");
        }
        return null;
    }

    static isLogined(): boolean {
        return ValidationUtil.isNotNullAndNotEmpty(AuthUtil.getLoginedInfo());
    }

    static getConsumerInformation(): MemberModel | null {
        let str = sessionStorage.getItem(CommonConstant.CONSUMER);
        if (ValidationUtil.isNotNullAndNotEmpty(str)) {
            return JSON.parse(str + "");
        }
        return null;
    }

    static getUserId(): string {
		return ValidationUtil.isNotNullAndNotEmpty(AuthUtil.getLoginedInfo()) ? ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userid) : "";
	}

}
