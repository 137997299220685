export class MyProgramConstant {
  static urlMyOffice = "/my-office";
  static urlShopping = "/shopping-mall";
  static urlHome = "/";

  static kindMenuTopLeft = "ml";
  static kindMenuTopRight = "mr";
  static kindMenuHome = "mh";
  static kindMenuOffice = "mo";
  static kindMenuShopping = "ms";
  static kindMenuFooter = "mf";
  static kindSidebar = "mb";

  static officeId = "ml.My-office";
  static shoppingId = "ml.Shopping-mall";
  static homeId = "ml.Home";

  static urlBrand = "/brand";
  static urlAbout = "/about";
  static urlnews = "/news";
  static urlCsService = "/cs-service";
  static urlSns = "/sns";


}
