// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  urlBetaIzex : "https://apl.beta-izex.ru/api/",
  keyBetaIzex : "88956aba576362ca65e2d1fcd3e9295a",
  urlWownet: 'https://sun.wownet.biz',
  production: false,
  default_lang: 'VN',
  comId: 'CHARNEVN',
  comCd: '02',
  //apiUrl: 'http://v621.wownet.biz/api'
  //apiUrl: 'http://localhost:8100/api'
  apiUrl: 'https://sun.wownet.biz/api'
};
