import { ValidationUtil } from '@app/common/util/validation.util';
import { environment } from '@enviroments/environment';
import { CompanyConstant } from '../constant/company.constant';
import { LangConstant } from '../constant/lang.constant';
import { AuthUtil } from './auth.util';
import { ConvertUtil } from './convert.util';
import { CommonConstant } from "@app/common/constant/common.constant";

export class CommonUtils {

    static getMessages(): string {
        return 'messages.' + CommonUtils.getLangMessage();
    }

    static getLangMessage(): string {
        let res = environment.default_lang.toLowerCase();
        let lang = CommonUtils.getLang();

        switch (lang) {
            case 'kr':
                res = 'kr';
                break;
            case 'en':
                res = 'en';
                break;
            case 'us':
                res = 'en';
                break;
            case 'vn':
                res = 'vn';
                break;
            default:
                res = 'en';
                break;
        }

        return res;
    }

    static getLang(): string {
        // let loginInfo = AuthUtil.getLoginedInfo();
        let lang = sessionStorage.getItem(LangConstant.STR_LANG);

        if (ValidationUtil.isNullOrEmpty(lang)) {
            // if (loginInfo) {
            //     lang = ConvertUtil.convertToString(loginInfo.ctrCd);
            // } else {
            //     lang = environment.default_lang;
            // }
            lang = environment.default_lang;
            sessionStorage.setItem(LangConstant.STR_LANG, lang);
        }

        return ConvertUtil.convertToString(lang).toLowerCase();
    }

    static getYN(value: string) {
        return (value === null || value === CommonConstant.NO || value === undefined) ? false : true;
    }
    static isLinkCart(): boolean {
        return ValidationUtil.isAnyNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.LINK_CART), sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
    }

    static isLinkGuest(): boolean {
        return ValidationUtil.isAnyNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.LINK_GUEST), sessionStorage.getItem(CommonConstant.CONSUMER))
            && (sessionStorage.getItem(CommonConstant.LINK_GUEST) === CommonConstant.YES);
    }

    static isGuestCart(): boolean {
        return ValidationUtil.isNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.GUEST_CART));
    }

    static isConsumer(): boolean {
        if (AuthUtil.isLogined()) {
            return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userKindCd).includes("U00");
        }
        let infoConsumer = AuthUtil.getConsumerInformation();
        if (ValidationUtil.isNotNullAndNotEmpty(infoConsumer)) {
            return ConvertUtil.convertToString(infoConsumer?.userKindCd).includes("U00");
        }
        return false;
    }

    static plus(number1: any, number2: any): number {
        return Number(number1) + Number(number2);
    }

    static minus(number1: any, number2: any): number {
        return Number(number1) - Number(number2);
    }

    static getDateNow() {
        let currentDate = new Date();

        const y = currentDate.getFullYear();
        const m = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
        const d = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

        return ConvertUtil.convertToString('' + y + m + d);
    }

    /**
     *
     * @param value
     * @returns the value after round half. ex: 2.3 -> 2
     */
    static roundHalf(value: number) {
        return Math.round(value * 2) / 2;
    }
}

